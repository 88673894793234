.login {
  background-color: #cbebf7 !important;
  height: 100% !important ;
}
.login-form {
  width: 450px !important;
  box-sizing: border-box;
  padding: 0 30px !important;
  border: 2px solid #89eda4 !important;
  border-radius: 15px !important;
  box-shadow: 0px 8px 16px 0px rgba(158, 158, 157, 0.5);
  background-color: #89eda4 !important;
}
.form-input {
  input::-ms-reveal,
  input::-ms-clear {
    display: none !important;
  }
}

.img-forget-password {
  height: 200px !important;
  // border-radius: 30% !important;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.forget-password-form {
  width: 30% !important;
}
.forget-password {
  background-color: #cbebf7 !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important
  ;
}
.username-input {
  width: 100%;
}
.login-button {
  color: black !important;
  width: 100% !important;
  // border-radius: 15px !important;
  height: 40px !important;
  background-color: white !important;
}
.person-image {
  width: 100%;
  height: 100%;
}
.sivabakkiyam-logo {
  width: 80px !important;
}

@media (min-width: 680px) and (max-width: 768px) {
  .login-form-main {
    background-color: #807a79 !important;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  .login-image {
    display: none !important;
  }
  .login-form {
    width: 300px !important;
    box-sizing: border-box;
  }
  .sivabakkiyam-logo {
    width: 120px !important;
  }
  .forget-password-form {
    width: 60% !important;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .login-image {
    display: none !important;
  }
  .login-form {
    width: 300px !important;
    box-sizing: border-box;
  }
  .sivabakkiyam-logo {
    width: 300px !important;
  }
  .forget-password-form {
    width: 90% !important;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  .login-image {
    display: none !important;
  }
  .login-form {
    width: 300px !important;
    box-sizing: border-box;
  }
  .sivabakkiyam-logo {
    width: 300px !important;
  }
  .forget-password-form {
    width: 90% !important;
  }
}

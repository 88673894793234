.auth-layout-container {
  height: 100vh !important;
}

.app-layout-container {
  .company-title {
    font-size: 24px;
    box-sizing: border-box;
    font-weight: 600;
    text-align: left;
    line-height: normal;
  }

  .tool-bar-container {
    min-height: auto !important;
  }
}
.cursur-pointer {
  cursor: pointer;
}
.left-menu-container {
  // width: 100%;
  margin: 0 40px !important;
  .menu-item-container {
    margin-bottom: 16px !important;
  }
}
.manage-align {
  align-items: center;
}
.arrow-icon {
  transform: rotate(90deg);
}
.profile-img {
  height: 35px !important;
  width: 35px !important;
  border-radius: 50% !important;
  //   border: 2px solid red;
}
.img-logo {
  height: 100%;
  width: 100%;
}
.logo-icon {
  height: 70px !important;
}
.profile-res1 {
  display: none;
}
.header-profile {
  display: flex;
}

@media (max-width: 650px) {
  .header-profile {
    display: contents;
  }
  .profile-res1 {
    display: block;
  }
  .menu-bar {
    margin-bottom: 50px !important;
  }
}
@media (max-width: 600px) {
}

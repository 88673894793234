.change-password{
    width: 50% !important;
    margin: auto !important;
}
.img-change-password{
  height: 150px !important;
  width: 150px !important;
}
@media (min-width: 769px) and (max-width: 1024px) {
    .change-password{
    
      width: 80% !important;
    }
  }
@media (min-width: 320px) and (max-width: 768px) {
    .change-password{
    
      width: 100% !important;
    }
  }
  